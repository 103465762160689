import request from "@/js/request";
function queryParams(query = {}) {
  let q = [];
  for (const key in query) {
    if (query[key] !== undefined && query[key] !== null && query[key] !== "") {
      q.push(`${key}=${encodeURIComponent(query[key])}`);
    }
  }
  let r = q.join("&");
  r = r ? "?" + r : "";
  return r;
}
// 获取车间
export function getWorkshop(data) {
  return request({
    url: "/workshop",
    method: "get",
    params: data,
  });
}

// 删除车间
export function deleteWorkshop(data, param, lang) {
  return request({
    url: "/workshop/del?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 新增车间
export function addWorkshop(data, param, lang) {
  return request({
    url: "/workshop/add?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 修改车间
export function editWorkshop(data, param, lang) {
  return request({
    url: "/workshop/update?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 查询设备组
export function getGroup(data) {
  return request({
    url: "/deviceGroup",
    method: "get",
    params: data,
  });
}

// 查询设备
export function getMachine(data) {
  return request({
    url: "/device",
    method: "get",
    params: data,
  });
}

// 查询用户
export function getUser(data) {
  return request({
    url: "/admin",
    method: "get",
    params: data,
  });
}

// 删除用户
export function deleteUser(data, param, lang) {
  return request({
    url: "/admin/remove?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 删除设备组
export function deleteGroup(data, param, lang) {
  return request({
    url: "/deviceGroup/del?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 新增设备组
export function addGroup(data, param, lang) {
  return request({
    url: "/deviceGroup/add?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 修改设备组
export function editGroup(data, param, lang) {
  return request({
    url: "/deviceGroup/update?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 删除设备
export function deleteMachine(data, param, lang) {
  return request({
    url: "/device/del?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 新增用户
export function addUser(data, param, lang) {
  return request({
    url: "/admin/create?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}
// 修改用户
export function editUser(data, param, lang) {
  return request({
    url: "/admin/update?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 上传图片
export function uploadMachines(data, param) {
  // 上传设备图片
  return request({
    url: "/upload?token=" + param,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

// 新增设备
export function addMachine(data, param, lang) {
  return request({
    url: "/device/add?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 修改设备
export function editMachine(data, param, lang) {
  return request({
    url: "/device/update?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 更换匹配码
export function changeMatchingCode(data, param, lang) {
  return request({
    url: "/device/changeMatchingCode?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 查看记录
export function getRecord(data, param, page, pagesize) {
  return request({
    url:
      "/device/operateLog?token=" +
      param +
      "&page=" +
      page +
      "&pagesize=" +
      pagesize,
    method: "post",
    data: data,
  });
}

// 修改温度
export function setTemperature(data, param, lang) {
  return request({
    url: "/device/remoteControl?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 修改开关
export function setPower(data, param, lang) {
  return request({
    url: "/device/switch?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 设备匹配码
export function matching(data, param, lang) {
  return request({
    url: "/device/matching?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 初始化设备
export function initMachine(data) {
  return request({
    url: "/rtk/init",
    method: "get",
    params: data,
  });
}

// 新增或移除设备
export function changeMachine(data, param, lang) {
  return request({
    url: "/device/toggle?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 查询车间、设备组、设备
export function listSearch(data, param, lang) {
  return request({
    url: "/workshop/mine?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 角色列表
export function roleList(data) {
  return request({
    url: "/admin/roleList",
    method: "get",
    params: data,
  });
}

// 查询权限
export function permissionList(data, param, lang) {
  return request({
    url: "/device/permissionList?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 修改权限
export function permissionChange(data, param, lang) {
  return request({
    url: "/device/updatePermission?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 查看设备匹配码状态
export function validate(data, param, lang) {
  return request({
    url: "/device/validate?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 获取分组列表
export function getGroupList(data = {}, token) {
  return request({
    url: "/group/search?token=" + token,
    method: "post",
    data: data,
  });
}

// 修改分组
export function editGroupList(data = {}, token) {
  return request({
    url: "/group/set?token=" + token,
    method: "post",
    data: data,
  });
}

// 修改偏移量
export function setOffsetTemp(data = {}, token) {
  return request({
    url: "/device/temperature_deviation?token=" + token,
    method: "post",
    data: data,
  });
}

// 获取图纸数据
export function getDrawData(serial_number, token) {
  const newParams = queryParams({ serial_number, token });
  return request.get(`/draw${newParams}`);
}

// 获取时序分组列表
export function getTimerGroupList(data = {}, token) {
  return request({
    url: "/time_group/search?token=" + token,
    method: "post",
    data: data,
  });
}
