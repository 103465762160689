import { Message, Loading } from "element-ui";
import i18n from "../lang";
let isRepeat = true;
export function showMessage(
  message,
  type = "warning",
  showClose = true,
  duration = 1000
) {
  if (!isRepeat) return;
  Message({
    showClose,
    message,
    type,
    duration,
  });
  isRepeat = false;
  setTimeout(() => {
    isRepeat = true;
  }, 2000);
}

// 判断元素是否在视野范围内
// 参考地址 https://www.51sjk.com/b8b337948/
export function isInViewPort(element) {
  const viewWidth = window.innerWidth || document.documentElement.clientWidth;
  const viewHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const { top, right, bottom, left } = element.getBoundingClientRect();

  return top >= 0 && left >= 0 && right <= viewWidth && bottom <= viewHeight;
}

export function isEmpty(val) {
  if (
    val === null ||
    val === undefined ||
    val === "null" ||
    val === "undefined"
  ) {
    return true;
  }
  if (typeof val === "string" && val.trim() === "") {
    return true;
  }
  if (Array.isArray(val) && val.length === 0) {
    return true;
  }
  if (typeof val === "object" && Object.keys(val).length === 0) {
    return true;
  }
  return false;
}
// 摄氏度转华氏度
export function celsiusToFahrenheit(celsius) {
  let fahrenheit = (Number(celsius) * 9) / 5 + 32;
  return Number(fahrenheit);
}
export function awaitWraper(promise) {
  return promise.then((res) => [null, res]).catch((err) => [err, null]);
}

let loadingTimeoutObj = null;
let loading;
export const showLoading = (text = i18n.t("Tip.Loading")) => {
  loading = Loading.service({
    lock: true,
    text,
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
  if (loadingTimeoutObj) {
    clearTimeout(loadingTimeoutObj);
  }
  loadingTimeoutObj = setTimeout(() => {
    loading?.close();
  }, 8 * 1000);
  return loading;
};
export function autoFitView(canvas) {
  if (canvas.getObjects().length < 1) {
    return;
  }
  canvas.setZoom(1);
  const group = new fabric.Group(canvas.getObjects());
  const x = group.left + group.width / 2 - canvas.width / 2;
  const y = group.top + group.height / 2 - canvas.height / 2;
  canvas.absolutePan({ x: x, y: y });
  const heightDist = canvas.getHeight() - group.height;
  const widthDist = canvas.getWidth() - group.width;
  let groupDimension = 0;
  let canvasDimension = 0;
  if (heightDist < widthDist) {
    groupDimension = group.height;
    canvasDimension = canvas.getHeight();
  } else {
    groupDimension = group.width;
    canvasDimension = canvas.getWidth();
  }
  const zoom = (canvasDimension / groupDimension) * 0.8;
  canvas.zoomToPoint({ x: canvas.width / 2, y: canvas.height / 2 }, zoom);
  canvas.requestRenderAll();
  group.ungroupOnCanvas();

}
