<template>
  <div class="main">
    <div class="app-wrapper">
      <div class="machine_title">{{ $t("Machine.Alarm") }}</div>
      <div class="machine_search">
        <div class="machine_search_list">
          <div class="search_title">{{ $t("Machine.Name") }}</div>
          <div class="search_selected" style="position: relative; width: 206px">
            <!-- <el-input :label="$t('Machine.Name')" v-model="list.device_name" :placeholder="$t('Warning.inputName')" clearable/> -->
            <el-autocomplete
              v-model="list.device_name"
              :fetch-suggestions="querySearch"
              :placeholder="$t('Machine.Name')"
              style="width: 100%"
              @blur="blueIpt"
              @focus="focusIpt"
            ></el-autocomplete>
            <img
              src="../../assets/image/clearable.png"
              @mousedown.stop="clearInput"
              class="clear"
              v-show="list.device_name && isClear"
            />
          </div>
        </div>
        <div class="machine_search_list">
          <div class="search_title">{{ $t("Warning.StartTime") }}</div>
          <div class="search_selected">
            <el-date-picker
              class="input-wrapper dt"
              v-model="list.starttime"
              type="datetime"
              size="mini"
              :clearable="isCleable"
              format="yyyy/MM/dd HH:mm"
              :picker-options="pickerOptionStart"
              value-format="yyyy/MM/dd HH:mm"
              :placeholder="$t('Warning.selectTime')"
            ></el-date-picker>
          </div>
        </div>
        <div class="machine_search_list">
          <div class="search_title">{{ $t("Warning.EndTime") }}</div>
          <div class="search_selected">
            <el-date-picker
              class="input-wrapper dt"
              v-model="list.endtime"
              :clearable="isCleable"
              type="datetime"
              size="mini"
              format="yyyy/MM/dd HH:mm"
              :picker-options="pickerOptionEnd"
              value-format="yyyy/MM/dd HH:mm"
              :placeholder="$t('Warning.selectEndTime')"
            ></el-date-picker>
          </div>
        </div>
        <div class="machine_search_btn">
          <div
            class="search_btn"
            @click="searchMachine"
            :style="{ background: isBlue ? '#3660DD' : '#4875ef' }"
          >
            {{ $t("Machine.Search") }}
          </div>
        </div>
        <div class="machine_search_btn" style="margin-left: 20px">
          <div
            class="search_btn"
            @click="reset"
            :style="{ background: isBlueSearch ? '#3660DD' : '#4875ef' }"
          >
            {{ $t("Warning.Reset") }}
          </div>
        </div>
        <div class="machine_search_space"></div>
      </div>
      <div class="table_content">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          @row-click="rowClick"
          :header-cell-style="{ background: '#dde0e8' }"
          highlight-current-row
          style="width: 100%"
          v-show="!noDataTable"
          row-key="id"
          :expand-row-keys="expandRowKeys"
          @expand-change="expandChange"
        >
          <el-table-column width="20" align="center" />
          <el-table-column min-width="20%" :label="$t('Warning.ErrorTime')">
            <template slot-scope="scope">
              <div>{{ scope.row.alerttime | time }}</div>
            </template>
          </el-table-column>
          <el-table-column min-width="20%" :label="$t('Warning.Error')">
            <template slot-scope="scope">
              <div>{{ scope.row.message }}</div>
            </template>
          </el-table-column>
          <el-table-column min-width="20%" :label="$t('Record.ZoneNumber')">
            <template slot-scope="scope">
              <div>
                {{ scope.row.group_number }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="20%"
            :label="$t('AddMachine.EquipmentName')"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.device_name }}</div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="20%"
            :label="$t('AddMachine.EquipmentModel')"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.product_model }}</div>
            </template>
          </el-table-column>
          <el-table-column type="expand" min-width="10%">
            <template slot-scope="props">
              <el-table
                ref="multipleTable"
                :data="props.row.deviceList"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="{ background: '#dde0e8' }"
              >
                <el-table-column width="35" align="center" />
                <el-table-column
                  :label="$t('Warning.ActualTemperature')"
                  prop="temperature"
                  min-width="22%"
                />
                <el-table-column
                  :label="$t('Warning.SettedTemperature')"
                  prop="target_temperature"
                  min-width="22%"
                />
                <el-table-column
                  :label="$t('Warning.Status')"
                  prop="status"
                  min-width="22%"
                  :formatter="formatStatus"
                />
                <el-table-column
                  :label="$t('Warning.Output')"
                  prop="output"
                  min-width="22%"
                />
                <el-table-column
                  :label="$t('Warning.Current')"
                  prop="current"
                  min-width="22%"
                />
                <el-table-column
                  :label="$t('Warning.TemperatureHigh')"
                  prop="upper_alarm_limit"
                  min-width="22%"
                />
                <el-table-column
                  :label="$t('Warning.TemperatureLow')"
                  prop="lower_alarm_limit"
                  min-width="22%"
                />
              </el-table>
            </template>
          </el-table-column>
          <el-table-column width="20" align="center" />
        </el-table>
      </div>
      <div class="pagination-container">
        <el-pagination
          background
          layout="prev, pager, next, sizes"
          :page-size="pagesize"
          :page-sizes="[5, 10, 15]"
          :current-page.sync="page"
          :total="total"
          v-show="total && total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
      <div v-show="noDataTable" class="noData">
        <img src="../../assets/image/emptyData.png" />
        <div class="empty_text">{{ $t("Warning.NoData") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import { getErrorMoldList } from '../../api/warning'
import { initMachine } from "../../api/machine";
import "../../style/table.less";
import { getWarning } from "@/api/warning";
import { isEmpty, celsiusToFahrenheit } from "../../js/util";
export default {
  name: "Machine",
  data() {
    return {
      machineNameList: [],
      isClear: false,
      tableData: [],
      expandRowKeys: [],
      isBlue: false,
      isBlueSearch: false,
      noDataTable: false,
      isCleable: false,
      list: {
        device_name: "",
        starttime: "",
        endtime: "",
      },
      total: 0,
      pickerOptionStart: {
        disabledDate: (time) => {
          if (this.list.endtime) {
            return time.getTime() > new Date(this.list.endtime).getTime();
          } else {
            // 不能大于当前日期
            return time.getTime() > Date.now();
          }
        },
      },
      pickerOptionEnd: {
        disabledDate: (time) => {
          if (this.list.starttime) {
            return (
              time.getTime() > Date.now() ||
              time.getTime() < new Date(this.list.starttime).getTime()
            );
          } else {
            return time.getTime() < Date.now();
          }
        },
      },
      page: 1,
      pagesize: 15,
    };
  },
  created() {
    // this.getEnd()
    // this.getStart()
    this.getWarning("start").then((data) => {});
    this.initMachine();
  },
  watch: {
    "$i18n.locale"(val) {
      this.changeLan();
    },
  },
  filters: {
    time(item) {
      const date = new Date(item * 1000);
      const month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
      const hour =
        date.getHours() >= 10 ? date.getHours() : "0" + date.getHours();
      const minute =
        date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes();
      return (
        date.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + minute
      );
    },
  },
  methods: {
    initMachine() {
      const params = {};
      params.token = localStorage.getItem("token");
      params.language = localStorage.getItem("language");
      initMachine(params).then((res) => {
        this.machineNameList = [];
        res.data.list.forEach((item) => {
          const filterArr = this.machineNameList.filter(
            (name) => name.value == item.name
          );
          if (filterArr.length < 1) {
            this.machineNameList.push({
              value: item.name,
              name: item.name,
            });
          }
        });
      });
    },
    querySearch(queryString, cb) {
      const filterArr = this.machineNameList.map((item) => {
        return {
          value: item.name,
          ...item,
        };
      });
      const results = queryString
        ? filterArr.filter(this.createFilter(queryString))
        : filterArr;
      cb(results);
    },
    createFilter(queryString) {
      return (data) => {
        return data.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    blueIpt() {
      setTimeout(() => {
        this.isClear = false;
      }, 20);
    },
    focusIpt() {
      setTimeout(() => {
        this.isClear = true;
      }, 20);
    },
    clearInput() {
      this.list.device_name = "";
    },
    // clickTable (row, index, e) {
    //   this.$refs.multipleTable.toggleRowExpansion(row)
    // },
    changeLan() {
      if (this.tableData.length > 0) {
        for (var i = 0; i < this.tableData.length; i++) {
          if (
            this.tableData[i].message === "温度过高" ||
            this.tableData[i].message === "AL-High"
          ) {
            this.$set(this.tableData[i], "message", this.$t("Tip.ALHigh"));
          } else if (
            this.tableData[i].message === "温度过低" ||
            this.tableData[i].message === "AL-Low"
          ) {
            this.$set(this.tableData[i], "message", this.$t("Tip.ALLow"));
          } else if (
            this.tableData[i].message === "热电偶开路" ||
            this.tableData[i].message === "Thermocouple Open"
          ) {
            this.$set(
              this.tableData[i],
              "message",
              this.$t("Tip.ThermocoupleOpen")
            );
          } else if (
            this.tableData[i].message === "热电偶反向" ||
            this.tableData[i].message === "Thermocouple Reverse"
          ) {
            this.$set(
              this.tableData[i],
              "message",
              this.$t("Tip.ThermocoupleReverse")
            );
          } else if (
            this.tableData[i].message === "热电偶短路" ||
            this.tableData[i].message === "Thermocouple Short"
          ) {
            this.$set(
              this.tableData[i],
              "message",
              this.$t("Tip.ThermocoupleShort")
            );
          } else if (
            this.tableData[i].message === "加热圈开路" ||
            this.tableData[i].message === "Heater Open"
          ) {
            this.$set(this.tableData[i], "message", this.$t("Tip.HeaterOpen"));
          } else if (
            this.tableData[i].message === "加热圈短路" ||
            this.tableData[i].message === "Heater Short"
          ) {
            this.$set(this.tableData[i], "message", this.$t("Tip.HeaterShort"));
          } else if (
            this.tableData[i].message === "温可控硅击穿" ||
            this.tableData[i].message === "Triac Breakdown"
          ) {
            this.$set(
              this.tableData[i],
              "message",
              this.$t("Tip.TriacBreakdown")
            );
          } else if (
            this.tableData[i].message === "保险丝断路" ||
            this.tableData[i].message === "Fuse Open"
          ) {
            this.$set(this.tableData[i], "message", this.$t("Tip.FuseOpen"));
          } else if (
            this.tableData[i].message === "漏电故障" ||
            this.tableData[i].message === "Leakage Current"
          ) {
            this.$set(
              this.tableData[i],
              "message",
              this.$t("Tip.LeakageCurrent")
            );
          } else if (
            this.tableData[i].message === "感温线和加热线混接" ||
            this.tableData[i].message ===
              "Mixed connection heater and T/C wire."
          ) {
            this.$set(this.tableData[i], "message", this.$t("Tip.Mixed"));
          }
        }
      }
    },
    // getEnd () {
    //   let end = new Date()
    //   end = new Date(end.getTime())
    //   const year = end.getFullYear()
    //   const month = (end.getMonth() + 1).toString().padStart(2, 0)
    //   const data = end.getDate().toString().padStart(2, 0)
    //   this.list.endtime = year + '/' + month + '/' + data + ' ' + '00:00'
    // },
    // getStart () {
    //   let start = new Date()
    //   start = new Date(start.getTime() - 60 * 60 * 24 * 1000 * 6)
    //   const year = start.getFullYear()
    //   const month = (start.getMonth() + 1).toString().padStart(2, 0)
    //   const data = start.getDate().toString().padStart(2, 0)
    //   this.list.starttime = year + '/' + month + '/' + data + ' ' + '00:00'
    // },
    reset() {
      this.isBlueSearch = true;
      setTimeout(() => {
        this.isBlueSearch = false;
      }, 500);
      this.list.device_name = "";
      // this.getStart()
      // this.getEnd()
      this.list.starttime = "";
      this.list.endtime = "";
      this.getWarning("start");
    },
    formatStatus(row, column) {
      return row.status === 1
        ? this.$t("Machine.Run")
        : row.status === 0
        ? this.$t("Machine.Stop")
        : row.status === 2
        ? this.$t("Machine.Standby")
        : this.$t("Machine.Warning");
    },
    searchMachine() {

      this.isBlue = true;
      setTimeout(() => {
        this.isBlue = false;
      }, 500);
      if (
        !this.list.device_name &&
        !this.list.starttime &&
        !this.list.endtime 
      ) {
        this.$message({
          showClose: true,
          message: this.$t("Tip.Criteria"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      // 初始第一页
      this.page = 1;
      this.getWarning();
    },
    // 处理温度
    handleTemperature(temp, unit) {
      if (isEmpty(temp)) return "--";
      if (unit == 1) {
        // 设置华氏度
        return Math.round(celsiusToFahrenheit(temp));
      }
      return temp;
    },
    getUnits(unit, temp) {
      if (isEmpty(temp)) return "";
      return unit === 1 ? "℉" : "℃";
    },
    async getWarning(type) {

      if (new Date(this.list.starttime) >= new Date(this.list.endtime)) {
        this.$message({
          showClose: true,
          message: this.$t("Tip.overDate"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: this.$t("Tip.Loading"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const params = {};
      const startTime = this.list.starttime;
      const endtime = this.list.endtime;
      params.device_name = this.list.device_name;
      params.starttime = startTime ? new Date(startTime).getTime() / 1000 : "";
      params.endtime = endtime ? new Date(endtime).getTime() / 1000 : "";
      params.page = this.page;
      params.pagesize = this.pagesize;
      params.token = localStorage.getItem("token");
      params.language = localStorage.getItem("language");
      const res = await getWarning(params);
      this.tableData = res.data.list;
      this.total = res.data.count;
      this.noDataTable = this.total === 0;
      for (var i = 0; i < this.tableData.length; i++) {
        const ary = [];
        const obj = this.tableData[i].device_info;
        ary.push(obj);

        this.$set(this.tableData[i], "deviceList", ary);
        this.$set(
          this.tableData[i].device_info,
          "upper_alarm_limit",
          this.handleTemperature(
            this.tableData[i].device_info.upper_alarm_limit,
            this.tableData[i].unit
          ) +
            "" +
            (this.tableData[i].unit === 0 ? "°C" : "°F")
        );
        this.$set(
          this.tableData[i].device_info,
          "target_temperature",
          this.handleTemperature(
            this.tableData[i].device_info.target_temperature,
            this.tableData[i].unit
          ) +
            "" +
            (this.tableData[i].unit === 0 ? "°C" : "°F")
        );
        this.$set(
          this.tableData[i].device_info,
          "temperature",
          this.handleTemperature(
            this.tableData[i].device_info.temperature,
            this.tableData[i].unit
          ) +
            "" +
            (this.tableData[i].unit === 0 ? "°C" : "°F")
        );
        this.$set(
          this.tableData[i].device_info,
          "lower_alarm_limit",
          this.handleTemperature(
            this.tableData[i].device_info.lower_alarm_limit,
            this.tableData[i].unit
          ) +
            "" +
            (this.tableData[i].unit === 0 ? "°C" : "°F")
        );
      }
      this.changeLan();
      loading.close();
      return Promise.resolve(res.data.list);
    },
    handleSizeChange(val) {
      this.page = 1;
      this.pagesize = val;
      this.getWarning("start");
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getWarning("start");
    },
    row_key(row) {
      return row.id;
    },
    expandChange(row, rowList) {
      this.expandRowKeys = [];
      rowList.forEach((item) => {
        this.expandRowKeys.push(item.id);
      });
    },
    rowClick(row, event, column) {
      // eslint-disable-next-line no-extend-native
      Array.prototype.remove = function (val) {
        const index = this.indexOf(val);
        if (index > -1) {
          this.splice(index, 1);
        }
      };

      if (this.expandRowKeys.indexOf(row.id) < 0) {
        this.expandRowKeys.push(row.id);
      } else {
        this.expandRowKeys.remove(row.id);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.clear {
  width: 14px;
  height: 14px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 10px;
  font-weight: bold;
}
.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;

  position: absolute;
  flex-direction: column;
  left: 0;
  top: 200px;
  .empty_text {
    line-height: 20px !important;
    font-weight: bold;
    font-size: 16px;
    color: #909399;
  }
}
.pagination-container {
  margin: 20px 25px;
  float: left;
}
.app-wrapper /deep/ .el-table__empty-text {
  line-height: 20px !important;
  font-weight: bold;
  font-size: 16px;
  color: #909399;
}
.main {
  width: 100%;
  height: 100%;
  background-color: #ebecee;

  .app-wrapper {
    background-color: #ebecee;
    height: 100%;
    .table_content {
      height: calc(100vh - 200px);
      overflow: auto;
      /deep/ .el-table__body-wrapper {
        height: auto;
        overflow-y: auto;
        background-color: #ebecee;
        overflow-x: hidden;
      }
      /deep/ .el-table tr {
        // background-color: #ebecee !important;
        cursor: pointer;
      }
    }
    .machine_title {
      padding: 22px 20px;
      color: #414141;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
    }
    .machine_search {
      display: flex;
      flex-direction: row;
      padding: 0px 25px;
      .machine_search_list {
        margin-right: 20px;
        // flex: 2.5;
        .search_title {
          float: left;
          text-align: left;
          height: 32px;
          line-height: 32px;
          margin: 10px 10px 10px 0;
          color: #4d4f5c;
          font-size: 18px;
        }
        .search_selected {
          float: left;
          margin: 10px 10px 10px 0;
          /deep/ .el-date-editor.el-input,
          /deep/ .el-date-editor.el-input__inner {
            width: 100%;
          }
          /deep/ .el-input__inner {
            height: 32px;
            line-height: 32px;
          }
          /deep/ .el-input__suffix-inner .el-input__icon {
            margin-top: -2px;
          }
          /deep/ .el-input__icon {
            margin-top: 5px;
            color: #a4afb7;
            font-weight: bold;
          }
          /deep/ .input-wrapper .el-input__icon {
            margin-top: 2px;
          }
          /deep/ .el-select .el-input .el-select__caret.is-reverse {
            margin-top: -3px;
          }
        }
      }
      .machine_search_btn {
        flex: 0.55;
        .search_btn {
          width: 70px;
          height: 32px;
          background: #4875ef;
          font-size: 13px;
          color: #f2f3f6;
          text-align: center;
          line-height: 32px;
          border-radius: 30px;
          margin: 10px 0px;
          cursor: pointer;
        }
        .edi_color {
          background-color: #767e95;
        }
      }
      .machine_search_space {
        flex: 5.4;
      }
    }
  }
}
</style>
